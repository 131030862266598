


















import { Component, Vue } from "vue-property-decorator";
import { Wallet } from "@/data/wallet";

@Component
export default class BalanceWallet extends Vue {
  wallet: Wallet = new Wallet();

  get balance(): any {
    return this.$store.getters.balance;
  }

  loadWallet() {
    this.$store.dispatch("wallet");
  }
  doWithdrawal() {
    this.$router.push({
      name: "write-off-form"
    });
  }
  initReplenishment() {
    this.$router.push({
      name: "replenishment-form"
    });
  }
  created() {
    this.loadWallet();
  }
}
