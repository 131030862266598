
















import { Component, Vue, Watch } from "vue-property-decorator";
import BalanceWallet from "@/components/layouts/balance-wallet.vue";
import { DateUtils, CPaged, onTableFetched } from "eka.core";
import WalletResource from "@/resources/wallet";
import {
  IWalletOperationRow,
  IWalletOperationsListRequest,
  WalletOperationsFactory
} from "@/data/wallet";
import { IPaymentResponseQuery } from "@/data/replenishment";
import {
  getCommonStore,
  NotifyMutationPayload,
  NotificationType,
  INotification
} from "eka.core";

@Component({
  components: {
    BalanceWallet
  }
})
export default class OperationsListView extends Vue {
  rows: IWalletOperationRow[] = [];
  dates = {
    beginDate: DateUtils.daysFromNow(-7),
    endDate: DateUtils.endOfDay()
  };
  paged: CPaged = new CPaged();

  getRequest(page: number): IWalletOperationsListRequest {
    return {
      afterDateTime: this.dates.beginDate,
      beforeDateTime: this.dates.endDate,
      ...this.paged.getRequest(page)
    };
  }
  loadList(page: number = 0, isUpdated: boolean = false) {
    return WalletResource.loadWalletOperations(this.getRequest(page)).then(
      async res => {
        const rows = res.items.map(item => WalletOperationsFactory(item));
        return onTableFetched.call(this, res, rows, isUpdated);
      }
    );
  }
  get headers(): any[] {
    return [
      {
        label: "ID",
        name: "id",
        className: "d-sm"
      },
      {
        label: "Дата и время",
        name: "createdAt"
      },
      {
        label: "Сумма",
        name: "viewAmount",
        align: "right",
        cellClass: (row: IWalletOperationRow) => row.className
      },
      {
        label: "Тип",
        name: "typeLabel"
      },
      {
        label: "Статус",
        name: "label",
        cellClass: (row: IWalletOperationRow) => row.statusClass,
        className: "d-sm"
      },
      {
        label: "Transaction ID",
        name: "transactionId",
        className: "d-sm"
      }
    ];
  }

  @Watch("$route.query", { immediate: true })
  onReplenishmentComplete(r: IPaymentResponseQuery): void {
    if (r.errorCode || r.errorText) {
      getCommonStore().commit(
        new NotifyMutationPayload({
          message: "Произошла ошибка попробуйте снова",
          type: NotificationType.error,
          canClose: true,
          duration: 5000
        } as INotification)
      );
      this.$router.replace({
        name: "wallet-operations-list"
      });
    } else if (r.payed) {
      getCommonStore().commit(
        new NotifyMutationPayload({
          message: "Денежные средства скоро будут зачислены на ваш кошелек.",
          type: NotificationType.success,
          canClose: true,
          duration: 5000
        } as INotification)
      );
      this.$router.replace({
        name: "wallet-operations-list"
      });
    }
  }
}
